import { RepeatIcon, Search2Icon } from '@chakra-ui/icons'
import { Box, Button, Center, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/system'
import Link from 'next/link'
import React, { useState } from 'react'

import { UkiyoePreview } from '@/components/UkiyoePreview'
import { randomPickupIds } from '@/lib/UkiyoeUtil'

type Props = {
  initialIds: number[]
}

export const ListedUkiyoes = ({ initialIds }: Props) => {
  const [ids, setIds] = useState(initialIds)

  const shuffle = () => {
    const newIds = randomPickupIds()
    setIds(newIds)
  }

  return (
    <>
      <chakra.section mt="8">
        <Heading as="h1" size="lg">
          Gallery
        </Heading>
        <Text mt="1" fontWeight="bold">
          Find your favorite one.
        </Text>
        <Center mt="4">
          <SimpleGrid minH={[128 * 4, 128 * 2]} w="full" columns={[2, 4]} p={2} spacing={2}>
            {ids.map((id) => {
              return <UkiyoePreview key={id} id={id} />
            })}
          </SimpleGrid>
        </Center>
        <Box mt="4" display={{ md: 'flex' }} justifyContent={{ md: 'center' }}>
          <Box>
            <Button
              onClick={shuffle}
              colorScheme="teal"
              variant="outline"
              minW={{ base: 'full', md: '60' }}
            >
              <RepeatIcon />
              <chakra.span ml="1">Shuffle</chakra.span>
            </Button>
          </Box>

          <Box ml={{ base: 0, md: 8 }} mt={{ base: 8, md: 0 }}>
            <a
              href="https://opensea.io/collection/cryptoukiyoe"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button colorScheme="teal" variant="outline" minW={{ base: 'full', md: '60' }}>
                <Search2Icon />
                <chakra.span ml="2">View on OpenSea</chakra.span>
              </Button>
            </a>
          </Box>
        </Box>
      </chakra.section>

      <chakra.section mt="16">
        <Box>
          <Link href="/attributes" prefetch={false}>
            <a>
              <Heading textDecoration="underline" as="h3" size="lg">
                Check All Types And Attributes
              </Heading>
            </a>
          </Link>
        </Box>
      </chakra.section>

      <chakra.section mt="16">
        <Box>
          <Link href="/faq" prefetch={false}>
            <a>
              <Heading textDecoration="underline" as="h3" size="lg">
                FAQ
              </Heading>
            </a>
          </Link>
        </Box>
      </chakra.section>
    </>
  )
}
