import { Box, Heading } from '@chakra-ui/react'
import React from 'react'

export const Intro = () => {
  return (
    <Box>
      <Heading as="h2" mt="8" fontSize="lg">
        5,000 unique collectible Ukiyo-e style digital art with proof of ownership stored on the
        Ethereum Blockchain.
      </Heading>
    </Box>
  )
}
