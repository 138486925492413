import { GetStaticProps } from 'next'
import React from 'react'

import { Container } from '@/components/Container'
import { Head } from '@/components/Head'
import { Header } from '@/components/Header'
import { Intro } from '@/components/Intro'
import { Layout } from '@/components/Layout'
import { ListedUkiyoes } from '@/components/ListedUkiyoes'

export const getStaticProps: GetStaticProps = async () => {
  const pickupIds = [3213, 4271, 3030, 318, 1042, 2065, 1236, 1971]

  return {
    props: {
      pickupIds,
    },
  }
}

type Props = {
  pickupIds: number[]
}

const Home = ({ pickupIds }: Props) => {
  return (
    <Layout>
      <Head path="/" />
      <Container>
        <Header />
        <Intro />
        <ListedUkiyoes initialIds={pickupIds} />
      </Container>
    </Layout>
  )
}

export default Home
